
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import { SystemRole, RoleTypes } from "@/store/modules/role/role.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const roleX = namespace("Role");

@Component({
  components: { DatePicker },
})
export default class EditRole extends Mixins(ValidationMixin) {
  @roleX.State(RoleTypes.UPDATE_ROLE_STATE)
  public updateRole!: DefaultState;

  @roleX.State(RoleTypes.ROLE_DATA)
  public systemRole!: SystemRole;

  @roleX.Mutation(RoleTypes.SET_UPDATE_ROLE_DIALOG)
  public setUpdateRole!: (updateRole: boolean) => void;

  @roleX.Action(RoleTypes.UPDATE_ROLE)
  public saveRole!: (farmer: SystemRole) => Promise<void>;

  public updateRoleStep = 1;

  public isValid = false;

  public role: SystemRole = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
    permissions: {
      admin: false,
      viewTickets: false,
      viewAnalytics: false,
      viewAdminControl: false,
      viewReports: false,
      viewUsers: false,
      viewDepartments: false,
      viewRoles: false,
      modifyTickets: false,
      modifyUsers: false,
      modifyRoles: false,
      modifyDepartments: false,
      modifyReports: false,
    },
  };

  @Ref("form") public formRef!: Validator;

  closeUpdateRoleDialog(): void {
    this.setUpdateRole(false);
  }

  async saveRoleToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveRole(this.role);
    }
  }

  @Watch("systemRole")
  onDataChanged(value?: SystemRole): void {
    this.role = Object.assign({}, value);
  }

  mounted(): void {
    this.role = Object.assign({}, this.systemRole);
  }

  @Watch("role.permission.admin")
  setAllPermissions(permission?: boolean): void {
    if (permission != undefined) {
      this.role.permissions.admin = permission;
      this.role.permissions.viewTickets = permission;
      this.role.permissions.viewAnalytics = permission;
      this.role.permissions.viewAdminControl = permission;
      this.role.permissions.viewReports = permission;
      this.role.permissions.viewUsers = permission;
      this.role.permissions.viewDepartments = permission;
      this.role.permissions.viewRoles = permission;
      this.role.permissions.modifyTickets = permission;
      this.role.permissions.modifyUsers = permission;
      this.role.permissions.modifyRoles = permission;
      this.role.permissions.modifyDepartments = permission;
      this.role.permissions.modifyReports = permission;
    }
  }

  @Watch("role.permissions.viewTickets")
  @Watch("role.permissions.viewAnalytics")
  @Watch("role.permissions.viewAdminControl")
  @Watch("role.permissions.viewReports")
  @Watch("role.permissions.viewUsers")
  @Watch("role.permissions.viewDepartments")
  @Watch("role.permissions.viewRoles")
  @Watch("role.permissions.modifyTiickets")
  @Watch("role.permissions.modifyUsers")
  @Watch("role.permissions.modifyRoles")
  @Watch("role.permissions.modifyDepartments")
  @Watch("role.permissions.modifyReports")
  observeRoles(): void {
    this.setIsAdmin();
  }

  setIsAdmin(): void {
    this.role.permissions.admin =
      this.role.permissions.viewTickets &&
      this.role.permissions.viewAnalytics &&
      this.role.permissions.viewAdminControl &&
      this.role.permissions.viewReports &&
      this.role.permissions.viewUsers &&
      this.role.permissions.viewDepartments &&
      this.role.permissions.viewRoles &&
      this.role.permissions.modifyTickets &&
      this.role.permissions.modifyUsers &&
      this.role.permissions.modifyRoles &&
      this.role.permissions.modifyDepartments &&
      this.role.permissions.modifyReports;
  }
}
